import React from "react";

import { Module } from "@tvg/amplitude/modules/raceCell/types";
import { useTopTracks, useUserPromos } from "@urp/lib-racetracks";
import {
  ColorTokens,
  Header,
  useColorTokens,
  useMediaQuery,
  breakpoints
} from "@tvg/design-system";
import CollapsibleWrapper from "@urp/tracks-builder/src/components/CollapsibleWrapper";
import { PromotionTypeEnum } from "@tvg/ts-types/Promos";
import TopTracksMask from "./TopTracksMask";
import {
  Container,
  CollapsibleWrapperStyled,
  UpperSection,
  LinkContainer
} from "./styled-components";

export interface TopTracksProps {
  title?: string;
  qaLabel?: string;
}

const TopTracks = ({ title, qaLabel = "topTracks" }: TopTracksProps) => {
  const isDesktop = useMediaQuery(breakpoints.tablet.min.sm);
  const { loading, tracksRaces, onTrackClickHandler } = useTopTracks();
  const { content } = useColorTokens() as ColorTokens;
  const { checkRacePromos } = useUserPromos(PromotionTypeEnum.RACE);

  if (loading) {
    return (
      <Container>
        <TopTracksMask isDesktop={isDesktop} qaLabel={`${qaLabel}-mask`} />
      </Container>
    );
  }

  if (tracksRaces.length === 0) {
    return null;
  }

  return isDesktop ? (
    <Container data-qa-label={qaLabel}>
      {title && (
        <UpperSection>
          <Header tag="h2" fontFamily="bold" color={content.strong}>
            {title}
          </Header>
          <LinkContainer
            to="/races?view=bytrack"
            data-qa-label={`${qaLabel}SeeAllButton`}
          >
            See all
          </LinkContainer>
        </UpperSection>
      )}
      <CollapsibleWrapperStyled>
        {tracksRaces.map((track) => (
          <CollapsibleWrapper
            key={track.code}
            qaLabel={`${{ qaLabel }}List`}
            track={track}
            onTrackClickHandler={onTrackClickHandler}
            module={Module.TOP_TRACKS}
            checkRacePromos={checkRacePromos}
          />
        ))}
      </CollapsibleWrapperStyled>
    </Container>
  ) : (
    <div data-qa-label={qaLabel}>
      {tracksRaces.map((track) => (
        <CollapsibleWrapper
          key={track.code}
          qaLabel={`${{ qaLabel }}List`}
          track={track}
          onTrackClickHandler={onTrackClickHandler}
          module={Module.TOP_TRACKS}
          checkRacePromos={checkRacePromos}
        />
      ))}
    </div>
  );
};

export default TopTracks;
