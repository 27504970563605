import React from "react";
import { useSelector } from "react-redux";
import { get } from "lodash";
import ApolloContext from "@tvg/utils/apolloContext";
import ApolloContextClients from "@tvg/utils/types/ApolloContext";
import HomeGrid from "@tvg/home";
import { HomeContainer } from "./styled-components";

export const Home = () => {
  const seoContent = useSelector((store) =>
    get(store, "capi.messages.seoContentHome", "")
  );

  return (
    <HomeContainer>
      <ApolloContext.Consumer>
        {(clients: ApolloContextClients) => (
          <HomeGrid
            shouldUpdate={typeof window !== "undefined"}
            device="desktop"
            seoContent={seoContent}
            graphClients={clients}
          />
        )}
      </ApolloContext.Consumer>
    </HomeContainer>
  );
};

export default Home;
